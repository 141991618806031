<template>
  <div class="p-t-10">
    <van-form @submit="onSubmit">
      <van-field v-model="code_sn" name="code_sn" label="二维码" autocomplete="off" placeholder="请输入二维码" required :rules="[{ required: true, message: '请输入二维码' }]" label-class='tw-flex tw-items-center'>
        <template #button>
          <van-button color='#5B9DFF' type="primary" size="small" native-type="submit">解除</van-button>
        </template>
      </van-field>
    </van-form>
    <p class="m-t-10 p-15 s_bf fz-14" v-if='msg'>结果：{{msg}}</p>
  </div>
</template>
<script>
import { ref, reactive, toRefs } from "@vue/reactivity";
import { Toast,Dialog } from 'vant';
import { iscode } from '@/utils/app.js';
import { codeDissolve } from '@/api/inWarehouse.js';
export default {
  setup(props) {
    const state = reactive({
      code_sn: '',
      msg:''
    })
    const onSubmit = async() =>{
      Dialog.confirm({
        title: '确定解除码关系？',
        confirmButtonColor:'#5B9DFF',
      }).then(async() => {
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration: 0
        });
        let result = await codeDissolve({code_sn:state.code_sn}).then(res => res.data).catch(error => error)
        Toast.clear();
        if (iscode(result)) {
          state.msg = '解除成功'
        } else {
          state.msg = result.msg
          Toast.fail(result.msg);
        }
      }).catch(() => {})
    }
    return{
      ...toRefs(state),
      onSubmit
    }
  }
}
</script>
